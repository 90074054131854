import $ from 'jquery'

var isNewsMouseDown = false
const targetName = "#scroll-image"
$(targetName).mousedown((e) => {
  e.preventDefault();
  isNewsMouseDown = true
  $(targetName).data({
    x: e.clientX,
    y: e.clientY,
    left: $(targetName).scrollLeft(),
    top: $(targetName).scrollTop(),
  })
})

$(targetName).mousemove((e) => {
  if (isNewsMouseDown) {
    target = $(targetName)
    var moveX = target.data('x') - e.clientX
    var moveY = target.data('y') - e.clientY
    target.scrollLeft(target.data('left') + moveX)
    target.scrollTop(target.data('top') + moveY)
  }
})

$(targetName).mouseup((e) => {
  isNewsMouseDown = false
})
