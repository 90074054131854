import $ from 'jquery'

// タブ選択によって、表示する内容を変更する
$('#special .select-tab .tab').on('click', (e) => {
  const tab = $(e.currentTarget).data("tab")

  $('#special .select-tab .tab .bk').css("display", "block")
  $('#special .select-tab .tab img').attr("src", "/assets/images/special_tab_bk.png")
  $('#special .content').css("display", "none")

  $('#special .select-tab .' + tab + ' .bk').css("display", "none")
  $('#special .select-tab .' + tab + ' img').attr("src", "/assets/images/special_tab.png")
  $('#special .content-' + tab).css("display", "block")
})



// タブ選択によって、表示する内容を変更する
$('#special-contents .select-tab .tab').on('click', (e) => {
  const tab = $(e.currentTarget).data("tab")

  $('#special-contents .select-tab .tab .bk').css("display", "block")
  $('#special-contents .select-tab .tab img').attr("src", "/assets/images/special_tab_bk.png")
  $('#special-contents .content').css("display", "none")

  $('#special-contents .select-tab .' + tab + ' .bk').css("display", "none")
  $('#special-contents .select-tab .' + tab + ' img').attr("src", "/assets/images/special_tab.png")
  $('#special-contents .content-' + tab).css("display", "block")
})
