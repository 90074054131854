import $ from 'jquery'

if ($(window).width() > 767) {
  setParticles("bg-gold-to-movie", true)
  setParticles("bg-gold-to-share", true)
} else {
  setParticles("bg-gold-to-movie", false)
  setParticles("bg-gold-to-share", false)
}

// spの際にfullScreen falseだと紙吹雪が途切れ途切れに表示されてしまうので
// fullScreen trueにする
// アクセス時の画面サイズで動作を変えるようにしている
function setParticles(id, isPcWidth) {
  var setParticles
  if (isPcWidth) {
    setParticles = tsParticles.load(id, {
      fullScreen: false,
      particles: setParticlesImage()
    });
  } else {
    setParticles = tsParticles.load(id, {
      fullScreen: true,
      particles: setParticlesImage()
    });
  }
  return setParticles
}

function setParticlesImage() {
  return {
    move: {
      direction: "bottom",
      enable: true,
      speed: {
        min: 1,
        max: 3
      }
    },
    number: {
      value: 50,
      density: {
        enable: true,
        area: 1000
      }
    },
    rotate: {
      value: {
        min: 0,
        max: 10
      },
      direction: "random",
      move: true,
      animation: {
        enable: true,
        speed: 60
      }
    },
    shape: {
      type: [
        "image",
      ],
      options: {
        image: [{
            src: "/assets/images/gold/fubuki_1.png",
            width: 12,
            height: 12,
            particles: {
              size: {
                value: 5
              }
            }
          },
          {
            src: "/assets/images/gold/fubuki_2.png",
            width: 12,
            height: 12,
            particles: {
              size: {
                value: 5
              }
            }
          },
          {
            src: "/assets/images/gold/fubuki_3.png",
            width: 12,
            height: 12,
            particles: {
              size: {
                value: 5
              }
            }
          },
          {
            src: "/assets/images/gold/fubuki_4.png",
            width: 12,
            height: 12,
            particles: {
              size: {
                value: 5
              }
            }
          },
          {
            src: "/assets/images/gold/fubuki_5.png",
            width: 12,
            height: 12,
            particles: {
              size: {
                value: 5
              }
            }
          },
          {
            src: "/assets/images/gold/fubuki_6.png",
            width: 12,
            height: 12,
            particles: {
              size: {
                value: 5
              }
            }
          }, {
            src: "/assets/images/gold/fubuki_7.png",
            width: 12,
            height: 12,
            particles: {
              size: {
                value: 5
              }
            }
          }, {
            src: "/assets/images/gold/fubuki_8.png",
            width: 12,
            height: 12,
            particles: {
              size: {
                value: 5
              }
            }
          }, {
            src: "/assets/images/gold/fubuki_9.png",
            width: 12,
            height: 12,
            particles: {
              size: {
                value: 5
              }
            }
          }, {
            src: "/assets/images/gold/fubuki_10.png",
            width: 12,
            height: 12,
            particles: {
              size: {
                value: 5
              }
            }
          }, {
            src: "/assets/images/gold/fubuki_11.png",
            width: 12,
            height: 12,
            particles: {
              size: {
                value: 8
              }
            }
          }, {
            src: "/assets/images/gold/fubuki_12.png",
            width: 12,
            height: 12,
            particles: {
              size: {
                value: 5
              }
            }
          }, {
            src: "/assets/images/gold/fubuki_13.png",
            width: 12,
            height: 12,
            particles: {
              size: {
                value: 10
              }
            }
          }, {
            src: "/assets/images/gold/fubuki_14.png",
            width: 12,
            height: 12,
            particles: {
              size: {
                value: 5
              }
            }
          }, {
            src: "/assets/images/gold/fubuki_15.png",
            width: 12,
            height: 12,
            particles: {
              size: {
                value: 5
              }
            }
          }, {
            src: "/assets/images/gold/fubuki_16.png",
            width: 12,
            height: 12,
            particles: {
              size: {
                value: 10
              }
            }
          }, {
            src: "/assets/images/gold/fubuki_17.png",
            width: 12,
            height: 12,
            particles: {
              size: {
                value: 5
              }
            }
          }, {
            src: "/assets/images/gold/fubuki_18.png",
            width: 12,
            height: 12,
            particles: {
              size: {
                value: 5
              }
            }
          }, {
            src: "/assets/images/gold/fubuki_19.png",
            width: 12,
            height: 12,
            particles: {
              size: {
                value: 10
              }
            }
          }, {
            src: "/assets/images/gold/fubuki_20.png",
            width: 12,
            height: 12,
            particles: {
              size: {
                value: 10
              }
            }
          }, {
            src: "/assets/images/gold/ef_1.png",
            width: 32,
            height: 32,
            particles: {
              size: {
                value: 5
              }
            }
          }, {
            src: "/assets/images/gold/ef_2.png",
            width: 32,
            height: 32,
            particles: {
              size: {
                value: 7
              }
            }
          }, {
            src: "/assets/images/gold/ef_3.png",
            width: 32,
            height: 32,
            particles: {
              size: {
                value: 10
              }
            }
          }, {
            src: "/assets/images/gold/ef_4.png",
            width: 32,
            height: 32,
            particles: {
              size: {
                value: 15
              }
            }
          }, {
            src: "/assets/images/gold/ef_5.png",
            width: 32,
            height: 32,
            particles: {
              size: {
                value: 15
              }
            }
          },
        ],
      }
    }
  }
}
