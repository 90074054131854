import $ from "jquery";

const IMG_BASE_PATH = "assets/images/character/modal/";

const CHARA_LIST = [
  "sibira",
  "rapis",
  "altia",
  "tram",
  "ashlyn",
  "fullon",
  "hekatie",
];

const charaModal = $("#chara-modal");
const close = $("#modal-close");

const pic = $("#pic");
const imgEle = $("#pic img");
const sourceEle = $("#pic source");
const stand = $("#stand img");
const attack = $("#attack img");
const walk = $("#walk-image");
const prof = $("#prof img");
const open = $(".chara-list  .chara-wrapper");
const left = $("#modal-left-move");
const right = $("#modal-right-move");

const isR18 = $("#isR18").data("r18");

let selectID;

//モーダルを開く時
open.on("click", (e) => {
  const charaName = $(e.currentTarget).data("name");
  selectID = CHARA_LIST.indexOf(charaName);
  openModalAction(charaName);
  addVisibleArrow(charaName);
});

// モーダルを閉じる時
close.on("click", () => {
  const closeData = close.data("name");
  charaModal.removeClass("disp-block");
  charaModal.addClass("disp-none");
  charaModal.removeClass("fadeIn");
  closeModal();

  if (!isR18) {
    pic.removeClass(`${closeData}-pos`);
  } else {
    pic.removeClass(`${closeData}-pos-r18`);
  }
});

const modalOpen = () => {
  document.body.style.overflow = "hidden";
};

const closeModal = () => {
  document.body.style.overflow = "";
};

const changeChara = (charaName) => {
  imgEle.attr("src", `${IMG_BASE_PATH}${charaName}_sp.png`);
  sourceEle.attr("srcset", `${IMG_BASE_PATH}${charaName}.png`);
  stand.attr("src", `${IMG_BASE_PATH}${charaName}_stand.gif`);
  attack.attr("src", `${IMG_BASE_PATH}${charaName}_attack.gif`);
  if (charaName === "tram") {
    $("#attack").css("width", "150%");
  } else if (charaName === "fullon") {
    $("#stand").css("width", "150%");
    $("#attack").css("width", "150%");
  } else if (charaName === "rapis") {
    walk.attr("src", `${IMG_BASE_PATH}chara_pu_walk.png`);
    $("#stand").css("width", "100%");
    $("#attack").css("width", "100%");
  } else {
    walk.attr("src", `${IMG_BASE_PATH}chara_pu_attack.png`);
    $("#stand").css("width", "100%");
    $("#attack").css("width", "100%");
  }
  prof.attr("src", `${IMG_BASE_PATH}${charaName}_txt.png`);
  if (!isR18) {
    pic.addClass(`${charaName}-pos`);
  } else {
    pic.addClass(`${charaName}-pos-r18`);
    const r18Bg = $(".r18-chara-bg img");
    r18Bg.attr("src", `${IMG_BASE_PATH}r18/${charaName}.png`);
  }
  close.data("name", charaName);
};

const openModalAction = (charaName) => {
  const secondClass = pic.attr("class").split(" ")[1];

  charaModal.removeClass("disp-none");
  charaModal.addClass("disp-block");
  charaModal.addClass("fadeIn");
  modalOpen();
  changeChara(charaName);
  pic.removeClass(secondClass);
};

const addVisibleArrow = (charaName) => {
  switch (charaName) {
    case "sibira":
      left.addClass("visible-hidden");
      right.removeClass("visible-hidden");
      break;
    case "hekatie":
      right.addClass("visible-hidden");
      left.removeClass("visible-hidden");
      break;
    default:
      left.removeClass("visible-hidden");
      right.removeClass("visible-hidden");
      return;
  }
};

//モーダルの左矢印でキャラ変更
left.on("click", () => {
  if (selectID < 1) return;
  if (selectID === 1) {
    left.addClass("visible-hidden");
  } else if (selectID >= CHARA_LIST.length - 1) {
    right.removeClass("visible-hidden");
  }
  selectID--;
  openModalAction(CHARA_LIST[selectID]);
});

//モーダルの右矢印でキャラ変更
right.on("click", () => {
  if (selectID >= CHARA_LIST.length - 1) return;
  if (selectID === 5) {
    right.addClass("visible-hidden");
  } else if (selectID < 1) {
    left.removeClass("visible-hidden");
  }
  selectID++;
  openModalAction(CHARA_LIST[selectID]);
});
